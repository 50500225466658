import styled from 'styled-components';
import tw from 'twin.macro';

export const MarkdownContainer = styled.div`
  
  ${tw`w-full py-8 md:py-4`}

  strong {
    ${tw`font-bold`};
  }

  strong a {
    ${tw`font-bold`};
  }

  h1 {
    ${tw`leading-tight text-3xl font-bold my-5 text-purple-900`};
  }

  h2 {
    ${tw`leading-tight text-2xl font-bold my-5 text-purple-900`};
  }

  h3 {
    ${tw`leading-tight text-xl font-bold my-5 text-purple-900`};
  }

  hr {
    ${tw`mb-6`}
  }

  p {
    ${tw`mb-6`};
  }

  blockquote {
    ${tw`border-l-4 bg-red-100 border-red-200 pl-4 pt-6 pb-1 font-bold text-base my-6`};
  }

  ul {
    ${tw`pl-5 mb-5 list-disc`};
  }

  ol {
    ${tw`pl-5 mb-5 list-decimal`};
  }

  li {
    ${tw`mt-2 mb-2`};
  }

  table {
    ${tw`table-auto my-6`}
  }

  th {
    ${tw`border px-4 py-2`}
  }

  td {
    ${tw`border px-4 py-2`}
  }

  code {
    ${tw`bg-gray-100`}
  }

`;
