import React from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import * as Styled from './styles';

const FormatHtml = ({ content }) => (
  <Styled.MarkdownContainer>
    <MDXRenderer>{content}</MDXRenderer>
  </Styled.MarkdownContainer>
);

FormatHtml.propTypes = {
  content: PropTypes.any.isRequired
};

export default FormatHtml;
